import { graphql, Link, navigate } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";
import { WpPage_Legal } from "~/_generated/types";
import moment from "moment";
import { Heading, HeadingSizeOption } from "~/components/elements/typography/Heading";
import { Subheading, SubheadingIconOption } from "~/components/elements/typography/Subheading";
import { Icon, IconType } from "~/components/elements/Icon";
import useLegal from "~/hooks/useLegalPages";
import Seo from "gatsby-plugin-wpgraphql-seo";

export default function legalPage({
	data: {
		wpPage: { legal },
		wp,
		wpPage,
	},
}: {
	data: { wpPage: { legal: WpPage_Legal } };
}) {
	const { nodes } = useLegal();

	const handleNavigate = (e) => {
		navigate(e);
	};

	let lastUpdatedDate = legal?.content?.lastUpdated;
	lastUpdatedDate = moment(lastUpdatedDate, "DD/MM/YYYY").format("Do MMMM YYYY");

	return (
		<Layout wp={wp} hideFooterCta>
			<Seo post={wpPage} />
			<section className="bg-purple-2 pb-[40px] pt-[90px] md:pb-[98px] md:pt-[195px]">
				<div className="container relative text-center">
					<div className="mx-auto max-w-[871px] space-y-8 text-center">
						{legal?.subheading && (
							<div className="flex justify-center">
								<Subheading text={legal?.subheading} icon={SubheadingIconOption.Legal} />
							</div>
						)}
						<Heading as="h1" size={HeadingSizeOption.h1} text={legal?.heading} />
					</div>
				</div>
			</section>

			<section className="my-12 lg:mb-[200px] lg:mt-[97px]">
				<div className="container">
					<div className="mx-auto max-w-[720px]">
						<div className="mt-[21px] flex items-center opacity-[.35]">
							<div className="fw-icon h-4 w-4">
								<Icon type={IconType.Clock} />
							</div>
							<p className="ml-3.5">Last Updated: {lastUpdatedDate}</p>
						</div>
						<div className="p-large prose mt-8" dangerouslySetInnerHTML={{ __html: legal?.content?.content }} />
					</div>
				</div>
			</section>
		</Layout>
	);
}

export const pageQuery = graphql`
	query Legal($id: String!) {
		wpPage(id: { eq: $id }) {
			...SEO
			...GeneratedWpPage
		}
		wp {
			...GeneratedWp
		}
	}
`;
